<template>
    <div class="customer-age-wrapper">
        <div
            class="birthday dashboard-container"
            :class="{
                'section-show': hasSectionClass,
                loading: isLoading
            }"
        >
            <div class="dashboard-title">
                近期生日
                <div
                    class="info-icon"
                    :tooltip="titleTips.birthday"
                    tooltip-touch
                />
            </div>
            <div v-if="isLoading" class="process-loading-icon" />
            <template v-if="birthdayData?.length > 0 || isLoading">
                <div class="data-container">
                    <div
                        v-for="(data, index) in birthdayData"
                        :key="index"
                        class="data-item"
                        :class="{ 'text-line': isLoading }"
                    >
                        <div
                            class="cake-icon"
                            :class="{
                                'is-birthday': isShowBirthday(data.oriBirthday)
                            }"
                        />
                        <div class="detail">
                            {{ data.name }}
                            <p>
                                {{ data.oriBirthday }}
                            </p>
                        </div>
                        <div class="age-text">
                            <span> {{ data.age }}</span
                            >歲
                        </div>
                    </div>
                </div>
                <Button
                    buttonStyle="dashboard"
                    buttonWord="查看更多"
                    :width="'100%'"
                    @click="queryOnCustomerPage('birthday')"
                />
            </template>
            <DashboardNoData
                v-else-if="
                    customerAgeData && birthdayData?.length === 0 && !isLoading
                "
                message="沒有近7天的壽星喔"
            />
            <DashboardErrorMessage
                v-else-if="!customerAgeData && !isLoading"
                @reacquire="getCustomerAgData"
            />
        </div>

        <div
            class="insurance-age dashboard-container"
            :class="{
                'section-show': hasSectionClass,
                loading: isLoading
            }"
        >
            <div class="dashboard-title">
                保險年齡將增加
                <div
                    class="info-icon"
                    :tooltip="titleTips.insBirthday"
                    tooltip-touch
                />
            </div>
            <div v-if="isLoading" class="process-loading-icon" />
            <template v-if="insBirthdayData?.length > 0 || isLoading">
                <div class="data-container">
                    <div
                        v-for="(data, index) in insBirthdayData"
                        :key="index"
                        class="data-item"
                        :class="{ 'text-line': isLoading }"
                    >
                        <div class="ins-age-increases-icon" />
                        <div class="detail">
                            {{ data.name }}
                            <p>{{ data.oriBirthday }}</p>
                        </div>
                        <div class="age-text">
                            <span> {{ data.age }}</span
                            ><span>+1</span>歲
                        </div>
                    </div>
                </div>
                <Button
                    buttonStyle="dashboard"
                    buttonWord="查看更多"
                    :width="'100%'"
                    @click="queryOnCustomerPage('insBirthday')"
                />
            </template>
            <DashboardNoData
                v-else-if="
                    customerAgeData &&
                    insBirthdayData?.length === 0 &&
                    !isLoading
                "
                message="暫時沒有符合條件的客戶"
            />
            <DashboardErrorMessage
                v-else-if="!customerAgeData && !isLoading"
                functionName="getDashboardCustomerAgeAPI"
                @reacquire="getCustomerAgData"
            />
        </div>
    </div>
</template>

<script>
// import _ from 'lodash'
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import DashboardNoData from '@/containers/home/DashboardNoData.vue'
import Button from '@/components/Button.vue'
import { getDashboardCustomerAgeAPI } from '@/assets/javascripts/api.js'

export default {
    name: 'DashboardCustomerAge',
    components: {
        Button,
        DashboardErrorMessage,
        DashboardNoData
    },
    methods: {
        getCustomerAgData: async function () {
            this.isLoading = true
            this.isError = false

            const payload = {
                birthdayRemain: 7,
                insBirthdayRemain: 42
            }

            try {
                const res = await getDashboardCustomerAgeAPI(payload)
                this.$store.commit('set', {
                    dashboardCustomerAge: res.data
                })
            } catch {
                this.$store.commit('set', {
                    dashboardCustomerAge: null
                })
                this.isError = true
            } finally {
                this.isLoading = false
            }
        },
        isShowBirthday: function (date = '1917-01-01') {
            const birthDay = date?.slice(5, 10)
            const toDay = new Date()
            const month = `${toDay.getMonth() + 1}`
            const day = `${toDay.getDate()}`

            return (
                birthDay === month.padStart(2, '0') + '-' + day.padStart(2, '0')
            )
        },
        queryOnCustomerPage: function (dropDownItem) {
            this.$setGaEvent(
                `filterCustomer_${dropDownItem}`,
                'cross-page-filter'
            )
            let startDate = this.$getDate()
            startDate.setYear(2020) //客戶查詢設定dropdown date的閏年
            let endDate = this.$getDate(dropDownItem === 'birthday' ? 7 : 42)
            endDate.setYear(2020) //客戶查詢設定dropdown date的閏年

            const query = {
                birthdayType: dropDownItem ?? 'all',
                dateStart: this.$formatDate(startDate),
                dateEnd: this.$formatDate(endDate)
            }
            this.$crossPageFilter('TiaCustomer', query, {}, true)
            //賓果事件
            if (dropDownItem === 'insBirthday') this.recordBingo()
        },
        recordBingo: function () {
            const data = {
                pageName: '首頁',
                action: `保險年齡即將增加_查看更多`
            }
            this.$recordBingo(data)
        }
    },
    computed: {
        dummyData: function () {
            return [{}, {}, {}]
        },
        customerAgeData: function () {
            return this.$store.state?.dashboardCustomerAge
        },
        birthdayData: function () {
            if (this.isLoading) return this.dummyData
            else if (this.customerAgeData === null) return []
            else return this.customerAgeData?.birthdayInfo?.slice(0, 3) ?? []
        },
        insBirthdayData: function () {
            if (this.isLoading) return this.dummyData
            else if (this.customerAgeData === null) return []
            else return this.customerAgeData?.insBirthdayInfo?.slice(0, 3) ?? []
        },
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        },
        titleTips: function () {
            return {
                birthday:
                    '・顯示所有帳號的客戶生日<br/>・以下人員為未來一週內即將生日與當天生日的客戶名單(顯示年齡是客戶當下的「歲數」)',
                insBirthday:
                    '・顯示所有帳號的客戶保險年齡增加日<br/>・以下人員為未來六週內「保險年齡」即將增加一歲客戶名單(訊息顯示的年齡是客戶即將要滿的「保險年齡」)'
            }
        }
    },
    watch: {
        isSectionShow: function (newVal) {
            //解決section CSS動畫的延遲造成跑版
            if (newVal) {
                this.hasSectionClass = this.isSectionShow
            } else {
                setTimeout(() => {
                    this.hasSectionClass = this.isSectionShow
                }, 400)
            }
        },
        customerAgeData: {
            handler(newVal) {
                if (newVal === null) {
                    this.isError = true
                } else {
                    this.isError = false
                }
            },
            deep: true
        }
    },
    data() {
        return {
            isLoading: false,
            isError: false,
            hasSectionClass: true
        }
    },
    async mounted() {
        if (
            !this.$store.state.dashboardCustomerAge ||
            (this.$store.state.dashboardCustomerAge &&
                Object.keys(this.$store.state?.dashboardCustomerAge).length ===
                    0) ||
            this.$shouldUpdateHomeApi()
        )
            await this.getCustomerAgData()
    }
}
</script>
<style lang="scss" scoped>
.customer-age-wrapper {
    display: flex;
    @media screen and (max-width: 460px) {
        flex-direction: column;
    }
}

.dashboard-title {
    margin-bottom: 14px;
    @media screen and (max-width: 1018px) {
        margin-bottom: 10px;
    }
}

.section-show {
    &.insurance-age {
        .dashboard-title {
            display: flex;
            align-items: baseline;
            gap: 3px;
        }
    }
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
    position: relative;
    min-height: 212.5px;
    &.section-show {
        padding: 10px 10px;
    }
    &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 1280px) {
        padding: 10px 10px;
    }
    @media screen and (max-width: 460px) {
        flex-direction: column;
        width: auto;
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &.loading {
        button {
            visibility: hidden;
        }
    }
}

.info-icon {
    width: 14px;
    height: 14px;
}

.cake-icon,
.ins-age-increases-icon {
    width: 40px;
    height: 40px;
}

.data-container {
    height: 100%;
    min-height: 150px;
}

.data-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    max-width: 200px;
    margin-bottom: 14px;
    @media screen and (max-width: 1017px) {
        margin: 0 auto;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }
    .detail,
    .age-text {
        font-size: 12px;
        color: $sixth-black;
        p {
            color: $secondary-grey;
        }
    }
    .age-text {
        min-width: 55px;
        text-align: end;
        span {
            color: $fourth-blue;
            font-size: 15px;
            &:nth-child(2) {
                font-size: 12px;
            }
        }
    }
    &.text-line {
        height: 40px;
        .detail,
        .age-text,
        .cake-icon,
        .ins-age-increases-icon {
            display: none;
        }
    }
}

:deep(.button) {
    height: auto;
    padding: 5px 0;
    .button-word {
        font-size: 12px;
    }
}

:deep(.error-container),
:deep(.no-data) {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: calc(100% - 10px);
    margin: 0 auto;
}
.process-loading-icon {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}
</style>
